

.hero{
    width: 100%;
    height: 80%;
    position: relative;
    top: 0;
}
.hero-mid{
    width: 100%;
    height: 60vh;
}
img{
   
    width: 100%;
    height: 60vh;
    object-fit: cover;
    display: block;

}

.hero_txt{
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20rem;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 12;

    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.3);
    padding: 1.5rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 0.5rem;
    border: 1px solid rgba(229, 231, 235, 1);
}

.hero_txt h1{
    font-size: 3rem;
    font-weight: 800;
    /* background: #3c3c3c; */
    color: #d4da2e;
    border-radius: 2%;
    padding: 0.5rem 2rem;
}

.hero_txt p{
    font-size: 1.3rem;
    color: #fff;
    padding: O.5rem 0 2rem 0;

}

.hero_txt .show{
    
    text-decoration: none;
    background: #fff;
    padding: 1rem 2rem;
    border-radius: 6px;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 2px;
    color: var(--ToemeBlack);
   
   
}

@media screen and (max-width: 555px) {

    .hero_txt h1{
       padding: 10px 20px;
    }

    .hero_txt p{
        font-size: 1.1rem;
        padding: 0 0 2rem 0;

    }

    .hero_txt .show{
    
        padding: 0.6rem 1.1rem;
        border-radius: 6px;
        font-size: 1rem;
        
       
    }

    
}