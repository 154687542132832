.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* map css */
.leaflet-container {
  height: 500px;
  width: 100%;
}

.navbar-top {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.navbar-scrolled {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(15px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);
}

.navbar-top a, .navbar-scrolled a {
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.nav-button {
  background: linear-gradient(45deg, #d4da2e, #a0a51e);
  color: white;
  border-radius: 9999px;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.nav-button:hover {
  background: linear-gradient(45deg, #a0a51e, #d4da2e);
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.mobile-menu {
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(20px);
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}
/* New styles for active link indicator */
.navbar-top a, .navbar-scrolled a {
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  transition: all 0.3s ease;
}

.navbar-top a:hover, .navbar-scrolled a:hover {
  background: rgba(212, 218, 46, 0.1);
}

.navbar-top a.text-primary, .navbar-scrolled a.text-primary {
  background: rgba(212, 218, 46, 0.2);
}

/* Adjust the active indicator */
.navbar-top a span, .navbar-scrolled a span {
  height: 3px;
  border-radius: 3px;
  bottom: -2px;
}

.welding-background {
  background: 
              url('../../background.jpg'); 
  background-size: cover;
  background-blend-mode: overlay;
  color: white; /* Adjust text for contrast */
  position: relative;
  /* border-radius: 1%; */
}

.welding-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  /* background: radial-gradient(circle, rgba(255, 180, 0, 0.5), transparent 70%); */
  animation: sparks 2s infinite;
}

@keyframes sparks {
  0% {
    transform: translate(0, 0);
    opacity: 0.2;
  }
  50% {
    transform: translate(10px, -10px);
    opacity: 0.4;
  }
  100% {
    transform: translate(0, 0);
    opacity: 0.2;
  }
}
